html.dark {
  background-color: #525c2b;
  /* Set the path to your image */
  background-image: url('../public/arch-dark.svg');

  background-size: cover; /* 100% width, auto height */
  background-position: top center;
  background-repeat: no-repeat;
}

html {
  background-color: #bdc797;
  /* Set the path to your image */
  background-image: url('../public/arch.svg');

  background-size: cover; /* 100% width, auto height */
  background-position: top center;
  background-repeat: no-repeat;
}
